<template>
  <v-btn-toggle
    v-model="current"
    v-bind="$attrs"
    v-on="$listeners"
    background-color="toggleButtonBg"
    borderless
    mandatory
    :height="height"
    active-class="toggle-active"
  >
    <v-btn
      v-for="btn in buttons"
      :key="btn.id"
      outlined
      class="rounded toggleButtonColor--text"
      :class="dense ? 'px-4' : 'px-7 px-md-11'"
      :ripple="false"
      :value="btn.id"
      :disabled="btn.disabled"
      :height="height"
    >
      <v-hover #default="{ hover }">
        <span
          :class="[
            fontClass,
            { 'toggleButtonActiveColor--text': btn.id === current },
            { 'toggleButtonHoverColor--text': hover && btn.id !== current }
          ]"
        >{{ btn.name }}</span>
      </v-hover>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ToggleButton',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      default: 1
    },
    buttons: {
      type: Array,
      default: () => []
    },
    height: {
      type: String | Number,
      default: 46
    },
    dense: {
      type: Boolean,
      default: false
    },
    buttonTypeString: {
      type: Boolean,
      default: false
    },
    fontClass: {
      type: String,
      default: 'font-normal'
    }
  },
  computed: {
    current: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', this.buttonTypeString ? value : +value)
      }
    }
  },
}
</script>
