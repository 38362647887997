<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">number</div>
        </portal>
        
        <ActionButton :to="{ name: 'ManageNumbers' }">Manage numbers</ActionButton>
      </div>
      
      <div>
        <CancelButton :to="{ name: 'ManageNumbers' }">Close</CancelButton>
      </div>
    </div>
    
    <div class="number-container">
      <CardBuyNumbers
        class="border-box py-15 px-12 cardBg rounded-20"
        :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
        :description="description"
      />
    </div>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import CardBuyNumbers from '@/components/cards/CardBuyNumbers'

export default {
  name: 'CreateNumber',
  components: { ActionButton, CancelButton, CardBuyNumbers },
  data: () => ({
    description: {
      title: 'Setup buy numbers',
      text: []
    }
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    }
  },
}
</script>
